import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1c4468ea = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _33261682 = () => interopDefault(import('../pages/new-login.vue' /* webpackChunkName: "pages/new-login" */))
const _acac0cfc = () => interopDefault(import('../pages/new-my-orders/index.vue' /* webpackChunkName: "pages/new-my-orders/index" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _6a13e351 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _64a10ba3 = () => interopDefault(import('../pages/scgp.vue' /* webpackChunkName: "pages/scgp" */))
const _1e609130 = () => interopDefault(import('../pages/search-result.vue' /* webpackChunkName: "pages/search-result" */))
const _bd2e5a74 = () => interopDefault(import('../pages/semi-design/index.vue' /* webpackChunkName: "pages/semi-design/index" */))
const _7647f4fd = () => interopDefault(import('../pages/standard-products/index.vue' /* webpackChunkName: "pages/standard-products/index" */))
const _8d97ccfc = () => interopDefault(import('../pages/templates/index.vue' /* webpackChunkName: "pages/templates/index" */))
const _35f3024a = () => interopDefault(import('../pages/terms-condition.vue' /* webpackChunkName: "pages/terms-condition" */))
const _4b01347c = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _4824e135 = () => interopDefault(import('../pages/test-payment.vue' /* webpackChunkName: "pages/test-payment" */))
const _05bbf601 = () => interopDefault(import('../pages/auth/line.vue' /* webpackChunkName: "pages/auth/line" */))
const _187dcef6 = () => interopDefault(import('../pages/auth/line-link.vue' /* webpackChunkName: "pages/auth/line-link" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _e9c513e4 = () => interopDefault(import('../pages/auth/login-via-phone.vue' /* webpackChunkName: "pages/auth/login-via-phone" */))
const _e96e3de4 = () => interopDefault(import('../pages/auth/new-line.vue' /* webpackChunkName: "pages/auth/new-line" */))
const _5536e830 = () => interopDefault(import('../pages/auth/old-login-via-phone.vue' /* webpackChunkName: "pages/auth/old-login-via-phone" */))
const _33eac2b0 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _0fdb26a6 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _7a880b9f = () => interopDefault(import('../pages/auth/test.vue' /* webpackChunkName: "pages/auth/test" */))
const _4bf81892 = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _1e1b7948 = () => interopDefault(import('../pages/error/test-vue.vue' /* webpackChunkName: "pages/error/test-vue" */))
const _6a2806ec = () => interopDefault(import('../pages/my/cart/index.vue' /* webpackChunkName: "pages/my/cart/index" */))
const _0ae30e02 = () => interopDefault(import('../pages/my/designs/index.vue' /* webpackChunkName: "pages/my/designs/index" */))
const _35515431 = () => interopDefault(import('../pages/my/orders/index.vue' /* webpackChunkName: "pages/my/orders/index" */))
const _59f1c99a = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _96b17076 = () => interopDefault(import('../pages/products/customize/index.vue' /* webpackChunkName: "pages/products/customize/index" */))
const _396b63ab = () => interopDefault(import('../pages/products/old-index.vue' /* webpackChunkName: "pages/products/old-index" */))
const _5cd9079e = () => interopDefault(import('../pages/checkout/backup/__id_old.vue' /* webpackChunkName: "pages/checkout/backup/__id_old" */))
const _31826d72 = () => interopDefault(import('../pages/checkout/backup/_ids.vue' /* webpackChunkName: "pages/checkout/backup/_ids" */))
const _6ee5bd46 = () => interopDefault(import('../pages/checkout/bank-transfer-finished/_id.vue' /* webpackChunkName: "pages/checkout/bank-transfer-finished/_id" */))
const _0518cf15 = () => interopDefault(import('../pages/checkout/credit-card-finished/_id.vue' /* webpackChunkName: "pages/checkout/credit-card-finished/_id" */))
const _f37b4f78 = () => interopDefault(import('../pages/checkout/debit-direct/_id.vue' /* webpackChunkName: "pages/checkout/debit-direct/_id" */))
const _64edf5b0 = () => interopDefault(import('../pages/checkout/qrcode/_id.vue' /* webpackChunkName: "pages/checkout/qrcode/_id" */))
const _524eac7e = () => interopDefault(import('../pages/my/orders/_orderId/index.vue' /* webpackChunkName: "pages/my/orders/_orderId/index" */))
const _2f6d2bd0 = () => interopDefault(import('../pages/products/detail/_id/index.vue' /* webpackChunkName: "pages/products/detail/_id/index" */))
const _1e0dfe3b = () => interopDefault(import('../pages/products/detail/_id/checkout/index.vue' /* webpackChunkName: "pages/products/detail/_id/checkout/index" */))
const _1e68c45c = () => interopDefault(import('../pages/articles/_id.vue' /* webpackChunkName: "pages/articles/_id" */))
const _61a10506 = () => interopDefault(import('../pages/brief-design/_id.vue' /* webpackChunkName: "pages/brief-design/_id" */))
const _3362ffdb = () => interopDefault(import('../pages/checkout/_id.vue' /* webpackChunkName: "pages/checkout/_id" */))
const _7c7feec2 = () => interopDefault(import('../pages/payment-checkout/_id.vue' /* webpackChunkName: "pages/payment-checkout/_id" */))
const _7d2b62ed = () => interopDefault(import('../pages/payment-success/_id.vue' /* webpackChunkName: "pages/payment-success/_id" */))
const _bf2af826 = () => interopDefault(import('../pages/product-groups/_category/index.vue' /* webpackChunkName: "pages/product-groups/_category/index" */))
const _a86886b8 = () => interopDefault(import('../pages/sample-product/_id/index.vue' /* webpackChunkName: "pages/sample-product/_id/index" */))
const _dc992884 = () => interopDefault(import('../pages/sample-products/_category/index.vue' /* webpackChunkName: "pages/sample-products/_category/index" */))
const _a70e62a4 = () => interopDefault(import('../pages/semi-design/_id.vue' /* webpackChunkName: "pages/semi-design/_id" */))
const _6622beda = () => interopDefault(import('../pages/share-products/_url.vue' /* webpackChunkName: "pages/share-products/_url" */))
const _0efc7bd2 = () => interopDefault(import('../pages/tags/_tag.vue' /* webpackChunkName: "pages/tags/_tag" */))
const _2b9877e7 = () => interopDefault(import('../pages/test-id/_id.vue' /* webpackChunkName: "pages/test-id/_id" */))
const _71f0ab64 = () => interopDefault(import('../pages/customize-product/_design/_id.vue' /* webpackChunkName: "pages/customize-product/_design/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/th",
    component: _2dfb1658,
    name: "index___th"
  }, {
    path: "/en/articles",
    component: _1c4468ea,
    name: "articles___en"
  }, {
    path: "/en/faq",
    component: _ca324bc8,
    name: "faq___en"
  }, {
    path: "/en/new-login",
    component: _33261682,
    name: "new-login___en"
  }, {
    path: "/en/new-my-orders",
    component: _acac0cfc,
    name: "new-my-orders___en"
  }, {
    path: "/en/privacy",
    component: _37e8fae4,
    name: "privacy___en"
  }, {
    path: "/en/products",
    component: _6a13e351,
    name: "products___en"
  }, {
    path: "/en/scgp",
    component: _64a10ba3,
    name: "scgp___en"
  }, {
    path: "/en/search-result",
    component: _1e609130,
    name: "search-result___en"
  }, {
    path: "/en/semi-design",
    component: _bd2e5a74,
    name: "semi-design___en"
  }, {
    path: "/en/standard-products",
    component: _7647f4fd,
    name: "standard-products___en"
  }, {
    path: "/en/templates",
    component: _8d97ccfc,
    name: "templates___en"
  }, {
    path: "/en/terms-condition",
    component: _35f3024a,
    name: "terms-condition___en"
  }, {
    path: "/en/test",
    component: _4b01347c,
    name: "test___en"
  }, {
    path: "/en/test-payment",
    component: _4824e135,
    name: "test-payment___en"
  }, {
    path: "/th/articles",
    component: _1c4468ea,
    name: "articles___th"
  }, {
    path: "/th/faq",
    component: _ca324bc8,
    name: "faq___th"
  }, {
    path: "/th/new-login",
    component: _33261682,
    name: "new-login___th"
  }, {
    path: "/th/new-my-orders",
    component: _acac0cfc,
    name: "new-my-orders___th"
  }, {
    path: "/th/privacy",
    component: _37e8fae4,
    name: "privacy___th"
  }, {
    path: "/th/products",
    component: _6a13e351,
    name: "products___th"
  }, {
    path: "/th/scgp",
    component: _64a10ba3,
    name: "scgp___th"
  }, {
    path: "/th/search-result",
    component: _1e609130,
    name: "search-result___th"
  }, {
    path: "/th/semi-design",
    component: _bd2e5a74,
    name: "semi-design___th"
  }, {
    path: "/th/standard-products",
    component: _7647f4fd,
    name: "standard-products___th"
  }, {
    path: "/th/templates",
    component: _8d97ccfc,
    name: "templates___th"
  }, {
    path: "/th/terms-condition",
    component: _35f3024a,
    name: "terms-condition___th"
  }, {
    path: "/th/test",
    component: _4b01347c,
    name: "test___th"
  }, {
    path: "/th/test-payment",
    component: _4824e135,
    name: "test-payment___th"
  }, {
    path: "/en/auth/line",
    component: _05bbf601,
    name: "auth-line___en"
  }, {
    path: "/en/auth/line-link",
    component: _187dcef6,
    name: "auth-line-link___en"
  }, {
    path: "/en/auth/login",
    component: _8e1272a8,
    name: "auth-login___en"
  }, {
    path: "/en/auth/login-via-phone",
    component: _e9c513e4,
    name: "auth-login-via-phone___en"
  }, {
    path: "/en/auth/new-line",
    component: _e96e3de4,
    name: "auth-new-line___en"
  }, {
    path: "/en/auth/old-login-via-phone",
    component: _5536e830,
    name: "auth-old-login-via-phone___en"
  }, {
    path: "/en/auth/register",
    component: _33eac2b0,
    name: "auth-register___en"
  }, {
    path: "/en/auth/reset-password",
    component: _0fdb26a6,
    name: "auth-reset-password___en"
  }, {
    path: "/en/auth/test",
    component: _7a880b9f,
    name: "auth-test___en"
  }, {
    path: "/en/error/404",
    component: _4bf81892,
    name: "error-404___en"
  }, {
    path: "/en/error/test-vue",
    component: _1e1b7948,
    name: "error-test-vue___en"
  }, {
    path: "/en/my/cart",
    component: _6a2806ec,
    name: "my-cart___en"
  }, {
    path: "/en/my/designs",
    component: _0ae30e02,
    name: "my-designs___en"
  }, {
    path: "/en/my/orders",
    component: _35515431,
    name: "my-orders___en"
  }, {
    path: "/en/my/profile",
    component: _59f1c99a,
    name: "my-profile___en"
  }, {
    path: "/en/products/customize",
    component: _96b17076,
    name: "products-customize___en"
  }, {
    path: "/en/products/old-index",
    component: _396b63ab,
    name: "products-old___en"
  }, {
    path: "/th/auth/line",
    component: _05bbf601,
    name: "auth-line___th"
  }, {
    path: "/th/auth/line-link",
    component: _187dcef6,
    name: "auth-line-link___th"
  }, {
    path: "/th/auth/login",
    component: _8e1272a8,
    name: "auth-login___th"
  }, {
    path: "/th/auth/login-via-phone",
    component: _e9c513e4,
    name: "auth-login-via-phone___th"
  }, {
    path: "/th/auth/new-line",
    component: _e96e3de4,
    name: "auth-new-line___th"
  }, {
    path: "/th/auth/old-login-via-phone",
    component: _5536e830,
    name: "auth-old-login-via-phone___th"
  }, {
    path: "/th/auth/register",
    component: _33eac2b0,
    name: "auth-register___th"
  }, {
    path: "/th/auth/reset-password",
    component: _0fdb26a6,
    name: "auth-reset-password___th"
  }, {
    path: "/th/auth/test",
    component: _7a880b9f,
    name: "auth-test___th"
  }, {
    path: "/th/error/404",
    component: _4bf81892,
    name: "error-404___th"
  }, {
    path: "/th/error/test-vue",
    component: _1e1b7948,
    name: "error-test-vue___th"
  }, {
    path: "/th/my/cart",
    component: _6a2806ec,
    name: "my-cart___th"
  }, {
    path: "/th/my/designs",
    component: _0ae30e02,
    name: "my-designs___th"
  }, {
    path: "/th/my/orders",
    component: _35515431,
    name: "my-orders___th"
  }, {
    path: "/th/my/profile",
    component: _59f1c99a,
    name: "my-profile___th"
  }, {
    path: "/th/products/customize",
    component: _96b17076,
    name: "products-customize___th"
  }, {
    path: "/th/products/old-index",
    component: _396b63ab,
    name: "products-old___th"
  }, {
    path: "/en/checkout/backup/:_id_old?",
    component: _5cd9079e,
    name: "checkout-backup-_id_old___en"
  }, {
    path: "/en/checkout/backup/:ids?",
    component: _31826d72,
    name: "checkout-backup-ids___en"
  }, {
    path: "/en/checkout/bank-transfer-finished/:id?",
    component: _6ee5bd46,
    name: "checkout-bank-transfer-finished-id___en"
  }, {
    path: "/en/checkout/credit-card-finished/:id?",
    component: _0518cf15,
    name: "checkout-credit-card-finished-id___en"
  }, {
    path: "/en/checkout/debit-direct/:id?",
    component: _f37b4f78,
    name: "checkout-debit-direct-id___en"
  }, {
    path: "/en/checkout/qrcode/:id?",
    component: _64edf5b0,
    name: "checkout-qrcode-id___en"
  }, {
    path: "/en/my/orders/:orderId",
    component: _524eac7e,
    name: "my-orders-orderId___en"
  }, {
    path: "/en/products/detail/:id",
    component: _2f6d2bd0,
    name: "products-detail-id___en"
  }, {
    path: "/th/checkout/backup/:_id_old?",
    component: _5cd9079e,
    name: "checkout-backup-_id_old___th"
  }, {
    path: "/th/checkout/backup/:ids?",
    component: _31826d72,
    name: "checkout-backup-ids___th"
  }, {
    path: "/th/checkout/bank-transfer-finished/:id?",
    component: _6ee5bd46,
    name: "checkout-bank-transfer-finished-id___th"
  }, {
    path: "/th/checkout/credit-card-finished/:id?",
    component: _0518cf15,
    name: "checkout-credit-card-finished-id___th"
  }, {
    path: "/th/checkout/debit-direct/:id?",
    component: _f37b4f78,
    name: "checkout-debit-direct-id___th"
  }, {
    path: "/th/checkout/qrcode/:id?",
    component: _64edf5b0,
    name: "checkout-qrcode-id___th"
  }, {
    path: "/th/my/orders/:orderId",
    component: _524eac7e,
    name: "my-orders-orderId___th"
  }, {
    path: "/th/products/detail/:id",
    component: _2f6d2bd0,
    name: "products-detail-id___th"
  }, {
    path: "/en/products/detail/:id?/checkout",
    component: _1e0dfe3b,
    name: "products-detail-id-checkout___en"
  }, {
    path: "/th/products/detail/:id?/checkout",
    component: _1e0dfe3b,
    name: "products-detail-id-checkout___th"
  }, {
    path: "/en/articles/:id",
    component: _1e68c45c,
    name: "articles-id___en"
  }, {
    path: "/en/brief-design/:id?",
    component: _61a10506,
    name: "brief-design-id___en"
  }, {
    path: "/en/checkout/:id?",
    component: _3362ffdb,
    name: "checkout-id___en"
  }, {
    path: "/en/payment-checkout/:id?",
    component: _7c7feec2,
    name: "payment-checkout-id___en"
  }, {
    path: "/en/payment-success/:id?",
    component: _7d2b62ed,
    name: "payment-success-id___en"
  }, {
    path: "/en/product-groups/:category",
    component: _bf2af826,
    name: "product-groups-category___en"
  }, {
    path: "/en/sample-product/:id",
    component: _a86886b8,
    name: "sample-product-id___en"
  }, {
    path: "/en/sample-products/:category",
    component: _dc992884,
    name: "sample-products-category___en"
  }, {
    path: "/en/semi-design/:id?",
    component: _a70e62a4,
    name: "semi-design-id___en"
  }, {
    path: "/en/share-products/:url?",
    component: _6622beda,
    name: "share-products-url___en"
  }, {
    path: "/en/tags/:tag?",
    component: _0efc7bd2,
    name: "tags-tag___en"
  }, {
    path: "/en/test-id/:id?",
    component: _2b9877e7,
    name: "test-id-id___en"
  }, {
    path: "/th/articles/:id",
    component: _1e68c45c,
    name: "articles-id___th"
  }, {
    path: "/th/brief-design/:id?",
    component: _61a10506,
    name: "brief-design-id___th"
  }, {
    path: "/th/checkout/:id?",
    component: _3362ffdb,
    name: "checkout-id___th"
  }, {
    path: "/th/payment-checkout/:id?",
    component: _7c7feec2,
    name: "payment-checkout-id___th"
  }, {
    path: "/th/payment-success/:id?",
    component: _7d2b62ed,
    name: "payment-success-id___th"
  }, {
    path: "/th/product-groups/:category",
    component: _bf2af826,
    name: "product-groups-category___th"
  }, {
    path: "/th/sample-product/:id",
    component: _a86886b8,
    name: "sample-product-id___th"
  }, {
    path: "/th/sample-products/:category",
    component: _dc992884,
    name: "sample-products-category___th"
  }, {
    path: "/th/semi-design/:id?",
    component: _a70e62a4,
    name: "semi-design-id___th"
  }, {
    path: "/th/share-products/:url?",
    component: _6622beda,
    name: "share-products-url___th"
  }, {
    path: "/th/tags/:tag?",
    component: _0efc7bd2,
    name: "tags-tag___th"
  }, {
    path: "/th/test-id/:id?",
    component: _2b9877e7,
    name: "test-id-id___th"
  }, {
    path: "/en/customize-product/:design?/:id?",
    component: _71f0ab64,
    name: "customize-product-design-id___en"
  }, {
    path: "/th/customize-product/:design?/:id?",
    component: _71f0ab64,
    name: "customize-product-design-id___th"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
